import * as React from "react"
import Layout from "../components/Layout"
import greybg from '../images/backgrounds/greybg.jpg'
import optimus from '../images/mars-optimus-otv.webp'
import donut from '../images/donut.webp'
import shinybg from '../images/backgrounds/shinybg.webp'
import envelope from '../images/isometric-envelope.webp'
import parts from '../images/horizontal-parts.webp'
import graphic3 from '../images/graphic-3.webp'
import mars2 from '../images/mars-2.webp'
import graphic2 from '../images/graphic-2.webp'
import mars1 from '../images/mars-1.webp'


export default function IndexPage() {
    return (
        <Layout>

                <section className="h-screen w-screen flex flex-col justify-center items-center bg-slate-400" style={{backgroundImage: `url(${greybg})`}}>
                    <div className="h-full w-full px-20 flex flex-row bg-gradient-to-tl from-white/20 to-gray-500/30">

                        <div className="h-fit w-3/5 my-auto">
                            <h1 className="text-8xl drop-shadow-md font-geometry uppercase">Next<br/>Generation Space Voyages</h1>
                        </div>

                        <div className="h-fit w-2/5 my-auto p-20">
                            <img src={optimus} />
                        </div>

                    </div>
                </section>


                <section className="h-fit w-screen flex flex-row items-center bg-olive">

                    <div className="h-fit w-1/2 mx-28">
                        <h2 className="text-5xl drop-shadow-md font-geometry text-white text-left">Decentralised<br/>Mission Design</h2>
                        <text className="text-5xl drop-shadow-md font-geometry text-white text-left tracking-widest">...</text>
                        <p className="text-xl drop-shadow-md font-light tracking-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>

                    <div className="w-1/3 p-20 mr-5">
                        <img src={donut} />
                    </div>

                </section>


                <section className="h-screen w-screen flex flex-row items-center bg-cover" style={{backgroundImage: `url(${shinybg})`}}>

                    <div className="flex flex-col h-fit w-1/2 mx-28">
                        <p className="text-xl text-black font-light tracking-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <img className="w-4/5 mt-10" src={parts} />
                    </div>

                    <div className="w-1/2 mr-5">
                        <img src={envelope} />
                    </div>

                </section>


                <section className="h-fit w-screen flex flex-row items-center bg-berry">

                    <div className="h-fit w-1/2 mx-28">
                        <h2 className="text-5xl drop-shadow-md font-geometry text-white text-left">Dynamic {'&'} Fast<br/>Deployment</h2>
                        <text className="text-5xl drop-shadow-md font-geometry text-white text-left tracking-widest">...</text>
                        <p className="text-xl drop-shadow-md font-light tracking-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>

                    <div className="w-1/3 p-20 mr-5">
                        <img src={graphic3} />
                    </div>

                </section>


                <section className="h-screen w-screen flex flex-row items-center bg-cover" style={{backgroundImage: `url(${mars2})`}} />


                <section className="h-fit w-screen flex flex-row items-center bg-sky">

                    <div className="h-fit w-1/2 mx-28">
                        <h2 className="text-5xl drop-shadow-md font-geometry text-white text-left">Democratised<br/>Space Exploration</h2>
                        <text className="text-5xl drop-shadow-md font-geometry text-white text-left tracking-widest">...</text>
                        <p className="text-xl drop-shadow-md font-light tracking-wide">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>

                    <div className="w-1/3 p-20 mr-5">
                        <img src={graphic2} />
                    </div>

                </section>


                <section className="h-screen w-screen flex flex-row items-center bg-cover" style={{backgroundImage: `url(${mars1})`}} />

        </Layout>
    )
}
